<template>
  <div class="page-content">
    <base-header
      type="gradient-success"
      class="pb-6 pb-8 pt-5 pt-md-8"
    >
    </base-header>

    <div class="container-fluid mt--9 position-relative">
      <greeting />

      <div class="row mt-4">
        <div class="col-lg-7 mt-3">
          <need-to-be-review />
        </div>
        <div class="col-lg-5 mt-3">
          <flk-ongoing />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import usePageMeta from "@/composables/usePageMeta";
import { defineComponent } from "vue";
import { useMeta } from "vue-meta";
import { useRoute } from "vue-router";

import Greeting from '@/components/Dashboard/Greeting.vue'
import NeedToBeReview from '@/components/Dashboard/NeedToBeReview.vue'
import FlkOngoing from '@/components/Dashboard/FlkOngoing.vue'

export default defineComponent({
  components: {
    Greeting,
    NeedToBeReview,
    FlkOngoing
  },
  setup() {
    const route = useRoute();
    useMeta(usePageMeta(route).pageMeta);
  },
});
</script>
