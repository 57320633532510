<template>
  <div class="dg-container">
    <div class="dg-card">
      <div class="row">
        <div class="col-xl-2 d-flex align-items-center justify-content-center">
          <div class="dg-card-img-container">
            <img
              class="dg-card-img"
              src="/img/dashboard-greeting.svg"
              alt="Greeting"
            >
          </div>
        </div>
        <div class="col-xl-10 d-flex align-items-center">
          <div class="dg-card-content-container">
            <div class="dg-card-title-container">
              <h3 class="dg-card-title">Selamat Datang!</h3>
            </div>
            <div class="dg-card-desc-container">
              <p class="dg-card-desc">Halo Admin, periksa panel akun mu untuk melihat informasi penting mengenai Karir di Paninti. Kamu bisa membuat lowongan kerja baru jika ingin mencari orang untuk posisi tertentu.</p>
            </div>
            <div class="dg-card-btn-add-container">
              <button
                class="btn dg-card-btn-add"
                @click="goToAddJobVacancyPage"
              >+ Tambah Lowongan Kerja</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter()

    const goToAddJobVacancyPage = () => {
      router.push({
        name: 'Tambah Lowongan Kerja'
      })
    }

    return {
      goToAddJobVacancyPage
    }
  }
})
</script>

<style lang="scss" scoped>
.dg-container {
  .dg-card {
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    &-img-container {
      text-align: center;
      margin: 15px 0;
    }

    &-img {
      max-width: 100%;
      height: auto;
    }

    &-title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      color: #1659ab;
    }

    &-desc {
      margin-bottom: 0;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
    }

    &-btn-add-container {
      margin: 10px 0;
    }
    &-btn-add {
      padding: 12px 30px;
      margin: 10px 0;
      background: #1659ab;
      color: #fff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 40px;
    }
  }
}
</style>