<template>
  <div class="fo-card">
    <div class="fo-card-header-container">
      <h3 class="fo-card-title">
        Sedang Berjalan
      </h3>
      <div
        class="fo-card-total-count"
        v-if="jobVacancy.total <= 100"
      >{{ jobVacancy.total }}</div>
      <div
        class="fo-card-total-count"
        v-else
      >99+</div>
    </div>

    <el-scrollbar
      max-height="700px"
      class="fo-card-body"
    >
      <template v-if="jobVacancy.data.length > 0">
        <div
          class="fo-item"
          v-for="jv in jobVacancy.data"
          :key="jv.id"
        >
          <div>
            <h3 class="fo-item-title">{{ jv.title }}</h3>
            <p class="fo-item-date-ongoing">{{ jv.open_date + ' - ' + jv.close_date }}</p>
          </div>
          <div class="fo-item-footer">
            <div>
              <tag
                class="mr-2"
                :name="keyword.name"
                v-for="keyword in jv.keywords"
                :key="keyword.name"
              />
            </div>
            <div>
              <p class="fo-item-total-participant">{{ jv.applicant }} Peserta</p>
            </div>
          </div>
        </div>

        <div
          class="mt-4"
          v-if="jobVacancy.data.length < jobVacancy.total && getOngoingJobVacanciesPromised.state.loading === false"
        >
          <button
            @click="loadMoreJobVacancies"
            class="fo-card-btn-view-more"
          >Lihat 4 Lainnya</button>
        </div>
      </template>
      <template v-else>
        <div>
          <p class="mb-0">Belum ada lowongan perkerjaan yang sedang berjalan</p>
        </div>
      </template>
    </el-scrollbar>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import useAxios from "@/composables/useAxios";

import JobVacancyService from '@/services/JobVacancy'

export default defineComponent({
  setup() {
    const state = reactive({
      jobVacancy: {
        total: 0,
        current_page: 1,
        per_page: 4,
        data: []
      },
    })

    const getOngoingJobVacanciesPromised = useAxios((payload = {}) => {
      return JobVacancyService.superadmin.getOngoingJobVacancies(payload)
    })
    const getOngoingJobVacanciess = async () => {
      await getOngoingJobVacanciesPromised.createPromise({
        params: {
          page: state.jobVacancy.current_page,
          per_page: state.jobVacancy.per_page,
          paginate: true
        }
      })

      if (getOngoingJobVacanciesPromised.state.response) {
        state.jobVacancy.data.push(...getOngoingJobVacanciesPromised.state.data.data.data)
        state.jobVacancy = {
          ...state.jobVacancy,
          current_page: getOngoingJobVacanciesPromised.state.data.data.current_page,
          per_page: getOngoingJobVacanciesPromised.state.data.data.per_page,
          total: getOngoingJobVacanciesPromised.state.data.data.total
        }
      }
    }

    const loadMoreJobVacancies = async () => {
      state.jobVacancy.current_page += 1
      await getOngoingJobVacanciess()
    }

    onMounted(async () => {
      await getOngoingJobVacanciess()
    })

    return {
      ...toRefs(state),
      getOngoingJobVacanciesPromised,
      getOngoingJobVacanciess,
      loadMoreJobVacancies
    }
  }
})
</script>

<style lang="scss">
.fo-card {
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  &-header-container {
    border-bottom: 1px solid #d9dbe9;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
  }

  &-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 0;
  }

  &-total-count {
    background: #db2b39;
    color: #fff;
    border-radius: 50%;
    margin-left: 10px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-body {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
  }

  &-btn-view-more {
    width: 100%;
    background: #dceafa;
    border: 1.5px solid #1b6bce;
    box-sizing: border-box;
    border-radius: 40px;
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1b6bce;
    padding: 8px 0;
  }
}

.fo-item {
  padding: 15px 0 25px;
  border-bottom: 1px solid #d9dbe9;

  &-title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 5px;
  }

  &-date-ongoing {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 0;
  }

  &-footer {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
  }

  &-total-participant {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    color: #db2b39;
    margin-bottom: 0;
  }
}
</style>