<template>
  <div class="nr-card">
    <div class="nr-card-header-container">
      <h3 class="nr-card-title">
        Perlu Direview
      </h3>
      <div
        class="nr-card-total-count"
        v-if="flks.length <= 100"
      >{{ flks.length }}</div>
      <div
        class="nr-card-total-count"
        v-else
      >99+</div>
    </div>

    <div class="nr-card-body">
      <el-scrollbar
        max-height="700px"
        v-if="flks.length > 0"
      >
        <div class="row nr-card-list-ap-container">
          <div
            class="col-lg-6 mt-2"
            :class="{ 'mb-4': flks.length > 2 ? true : false }"
            v-for="flk in flks"
            :key="flk.id"
          >
            <div class="nr-card-ap">
              <div class="nr-card-ap-body">
                <p class="nr-card-ap-date-created">{{ flk.submitted_date }}</p>
                <p class="nr-card-ap-job-title">{{ flk.job_position.job_vacancy_title }}</p>
                <p class="nr-card-ap-name">{{ flk.name }}</p>
                <p class="nr-card-ap-email">{{ flk.email }}</p>
              </div>
              <div class="nr-card-ap-footer">
                <span
                  @click="goToDetailPage(flk.id)"
                  class="nr-card-ap-see-detail"
                >See Details</span>
              </div>
            </div>
          </div>
        </div>

      </el-scrollbar>

      <div v-else>
        <p class="mb-0">Belum ada flk yang perlu direview</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive } from "vue";
import useAxios from "@/composables/useAxios";

import FlkService from '@/services/Flk'
import { toRefs } from "@vueuse/core";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter()
    const state = reactive({
      flks: []
    })

    const goToDetailPage = (id) => {
      router.push({
        name: 'Detail Flk',
        params: {
          id
        }
      })
    }

    const getNotReviewedFlkPromised = useAxios(() => {
      return FlkService.superadmin.getNoReviewedFlks()
    })
    const getNotReviewedFlk = async () => {
      await getNotReviewedFlkPromised.createPromise()

      if (getNotReviewedFlkPromised.state.response) {
        state.flks = getNotReviewedFlkPromised.state.data.data
      }
    }

    onMounted(async () => {
      await getNotReviewedFlk()
    })

    return {
      ...toRefs(state),
      goToDetailPage,
      getNotReviewedFlkPromised,
      getNotReviewedFlk
    }
  }
})
</script>

<style lang="scss" scoped>
.nr-card {
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  &-header-container {
    border-bottom: 1px solid #d9dbe9;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
  }

  &-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 0;
  }

  &-total-count {
    background: #db2b39;
    color: #fff;
    border-radius: 50%;
    margin-left: 10px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-list-ap-container {
    margin-right: 10px;
  }

  &-body {
    padding-top: 25px;
  }
}

.nr-card-ap {
  background: #ffffff;
  border: 1px solid #d9dbe9;
  box-sizing: border-box;
  border-radius: 16px;

  &-date-created {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 10px;
  }

  &-body {
    padding: 20px 15px;
  }
  &-job-title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 3px;
  }
  &-name {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 3px;
  }
  &-email {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 3px;
    word-break: break-all;
  }

  &-footer {
    padding: 15px;
    text-align: center;
    border-top: 1px solid #d9dbe9;
  }
  &-see-detail {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #2c7fe4;
    cursor: pointer;
  }
}
</style>